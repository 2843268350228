import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import Sidebar from 'react-bootstrap-sidebar-menu';

import * as BootstrapIcons from 'react-bootstrap-icons';

import * as SolidIcons from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getLocationWithLang, Link } from 'components/helpers/RouterWrapper';

import { useAppSelector } from 'app/hooks';
import { selectAuthState } from 'features/account/accountSlice';
import { AuthContext } from 'features/account/AuthContext';

import styles from './ProtectedLayout.module.scss';

export function ProtectedLayout() {
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const authState = useAppSelector(selectAuthState);

  useEffect(() => {
    if (!authState.account) {
      navigate(getLocationWithLang('/login/', i18n));
    }
  }, [authState, navigate, i18n]);

  if (!authState.account) {
    return (
      <section className="section-padding">
        <Container>
          <Row>
            <Col sm={10} lg={8} xxl={5} className="mx-auto p-lg-5">
              <h3 className="text-danger">Unauthorized access</h3>
              <p>Please <Link to="/login/">sign in</Link>.</p>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }

  return (
    <AuthContext.Provider value={authState}>
      <div className={styles.container}>

        {/* TODO: move out Sidebar to a separate component */}
        {/* TODO: add icons to menu items */}
        <Sidebar expand="md" defaultExpanded={true} variant="light" bg="light" className={styles.sidebar}>
          <Sidebar.Collapse>

            <Sidebar.Header>
              <Sidebar.Brand>
                {authState.account.username}
              </Sidebar.Brand>
              <Sidebar.Toggle />
            </Sidebar.Header>

            <Sidebar.Body>
              <Sidebar.Nav>

                {/* defaultExpanded is not specified in types but valid here */}
                {/* @ts-ignore */}
                <Sidebar.Sub defaultExpanded={true}>

                  <Sidebar.Sub.Toggle>
                    <Sidebar.Nav.Icon />
                    <Sidebar.Nav.Title>
                      My Assets
                    </Sidebar.Nav.Title>
                  </Sidebar.Sub.Toggle>

                  <Sidebar.Sub.Collapse className="py-1">
                    <Sidebar.Nav className={styles.submenu}>

                      <Sidebar.Nav.Link as={Link} to="/account/offers/">
                        <Sidebar.Nav.Icon>
                          <BootstrapIcons.Percent size={20} className="mb-1" />
                        </Sidebar.Nav.Icon>
                        <Sidebar.Nav.Title>Offers</Sidebar.Nav.Title>
                      </Sidebar.Nav.Link>

                      <Sidebar.Nav.Link as={Link} to="/account/stores/">
                        <Sidebar.Nav.Icon>
                          <BootstrapIcons.ShopWindow size={20} className="mb-1" />
                        </Sidebar.Nav.Icon>
                        <Sidebar.Nav.Title>Stores</Sidebar.Nav.Title>
                      </Sidebar.Nav.Link>

                      <Sidebar.Nav.Link disabled>
                        <Sidebar.Nav.Icon>
                          <BootstrapIcons.BookmarkStar size={20} className="mb-1" />
                        </Sidebar.Nav.Icon>
                        <Sidebar.Nav.Title>Coupons</Sidebar.Nav.Title>
                      </Sidebar.Nav.Link>

                      <Sidebar.Nav.Link disabled>
                        <Sidebar.Nav.Icon>
                          <FontAwesomeIcon icon={SolidIcons.faMartiniGlass} size="lg" />
                        </Sidebar.Nav.Icon>
                        <Sidebar.Nav.Title>Restaurant</Sidebar.Nav.Title>
                      </Sidebar.Nav.Link>

                    </Sidebar.Nav>
                  </Sidebar.Sub.Collapse>

                </Sidebar.Sub>

                <Sidebar.Nav.Link as={Link} to="/account/">
                  <Sidebar.Nav.Icon>
                    <BootstrapIcons.PersonBoundingBox size={20} className="mb-1" />
                  </Sidebar.Nav.Icon>
                  <Sidebar.Nav.Title>Profile</Sidebar.Nav.Title>
                </Sidebar.Nav.Link>

              </Sidebar.Nav>
            </Sidebar.Body>

          </Sidebar.Collapse>
        </Sidebar>

        <div className={styles.content}>

          <Outlet />

        </div>

      </div>
    </AuthContext.Provider>
  );
}

import React, { useEffect } from 'react';

import { useParams } from 'react-router-dom';

import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import { Link } from 'components/helpers/RouterWrapper';
import { OfferEditForm } from 'components/forms/OfferEditForm/OfferEditForm';

import { Offer, emptyOffer } from 'features/private/offers/Offer';

import { EntityId } from '@reduxjs/toolkit';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { fetchOneOffer, selectById } from 'features/private/offers/offersSlice';

export function OfferEditPage() {
  const dispatch = useAppDispatch();

  let { offerId } = useParams();

  useEffect(() => {
    const id = parseInt(offerId || '0');
    dispatch(fetchOneOffer(id));
  }, [dispatch, offerId]);

  const offer: Offer = useAppSelector(state => selectById(state, offerId as EntityId)) || emptyOffer;

  if (offer.id === 0 && offerId !== "add") {
    // TODO: check loading state and redirect to the corresponding error page in case of error
    return (
      <div>404 Error</div>
    );
  }

  return (
    <section className="section-padding">
      <Container>

        <Row>
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item linkAs={Link} linkProps={{to: "/account/offers/"}}>My Offers</Breadcrumb.Item>
              <Breadcrumb.Item active>{offer.id ? "Edit Offer" : "Add New Offer"}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>

        <Row>
          <Col>
            <h2>{offer.title.length ? offer.title : "New Offer"}</h2>
          </Col>
        </Row>

        <Row>
          <Col xl={8}>
            <OfferEditForm offer={offer} navigateOnSuccess="/account/offers/" />
          </Col>
        </Row>

      </Container>
    </section>
  );
}

import React, { useEffect } from 'react';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import { Vendor } from 'types/Vendor';
import { VendorCard } from 'components/cards/VendorCard/VendorCard';

import { useAppSelector, useAppDispatch } from 'app/hooks';
import { fetchAllVendors, selectAll } from 'features/vendors/vendorsSlice';

import { ITEMS_IN_ROW } from 'components/constants';

export function VendorListPage() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchAllVendors());
  }, [dispatch]);

  const vendors: Vendor[] = useAppSelector(selectAll);

  // TODO: check loading state and redirect to the corresponding error page in case of error

  return (
    <section className="section-padding">

      <Container>

        <Row>
          <Col>
            <h2 className="h5 mb-4 text-gray-900">All Stores</h2>
          </Col>
        </Row>

        <Row className="g-4"
             xs={ITEMS_IN_ROW.xs}
             sm={ITEMS_IN_ROW.sm}
             md={ITEMS_IN_ROW.md}
             lg={ITEMS_IN_ROW.lg}
             xl={ITEMS_IN_ROW.xl}
        >
          {vendors.map((vendor, idx) => (
            <Col key={idx}>
              <VendorCard vendor={vendor} />
            </Col>
          ))}
        </Row>

      </Container>

    </section>
  );
}

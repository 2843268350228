import React from 'react';

import Card from 'react-bootstrap/Card';
import Carousel from 'react-multi-carousel';

import { Offer } from 'features/public/offers/Offer';
import { CardItem, CardItemTypeEnum } from 'types/CardItem';
import { Coupon } from 'types/Coupon';
import { Vendor } from 'types/Vendor';

import { CouponCard } from '../CouponCard/CouponCard';
import { OfferCard } from '../OfferCard/OfferCard';
import { VendorCard } from '../VendorCard/VendorCard';

import { ITEMS_IN_ROW } from 'components/constants';

type CardsCarouselProps = {
  items: CardItem[];
};

export function CardsCarousel({ items }: CardsCarouselProps) {
  const gridBreakpoints = {
    xs: {
      breakpoint: { min: 0, max: 576 },
      items: ITEMS_IN_ROW.xs,
    },
    sm: {
      breakpoint: { min: 576, max: 768 },
      items: ITEMS_IN_ROW.sm,
    },
    md: {
      breakpoint: { min: 768, max: 992 },
      items: ITEMS_IN_ROW.md,
    },
    lg: {
      breakpoint: { min: 992, max: 1200 },
      items: ITEMS_IN_ROW.lg,
    },
    xl: {
      breakpoint: { min: 1200, max: 1400 },
      items: ITEMS_IN_ROW.xl,
    },
    xxl: {
      breakpoint: { min: 1400, max: 1920 },
      items: ITEMS_IN_ROW.xxl,
    },
    xxxl: {
      breakpoint: { min: 1920, max: 4000 },
      items: ITEMS_IN_ROW.xxxl,
    },
  };

  return (
    <Carousel responsive={gridBreakpoints}>
      {items.map((item, idx) => {
        switch (item.type) {

          case CardItemTypeEnum.Coupon:
            return (
              <CouponCard coupon={item.item as Coupon} key={idx} />
            );

          case CardItemTypeEnum.Offer:
            return (
              <OfferCard offer={item.item as Offer} key={idx}/>
            );

          case CardItemTypeEnum.Vendor:
            return (
              <VendorCard vendor={item.item as Vendor} key={idx}/>
            );

          default:
            return (
              <Card className={`h-100 shadow-sm`}>
                <Card.Body>
                  <h6 className="text-danger">Error: Card type not found.</h6>
                </Card.Body>
              </Card>
            );

        }
      })}
    </Carousel>
  );
}

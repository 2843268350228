import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import * as RegularIcons from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Offer } from 'features/public/offers/Offer';

import { Link } from 'components/helpers/RouterWrapper';
import { VendorButtons } from 'components/VendorButtons/VendorButtons';

import styles from './OfferCard.module.scss';

type OfferCardProps = {
  offer: Offer;
};

const getRemainingDays = (expiryDate: string): number => {
  const diff: number = (new Date(expiryDate)).getTime() - (new Date().getTime());

  if (diff < 0) {
    return 0;
  }

  return Math.ceil(
    Math.abs(diff / 86400000)
  );
};

export function OfferCard({ offer }: OfferCardProps) {
  const { t } = useTranslation();

  const remainingDays: number = offer.expiryDate ? getRemainingDays(offer.expiryDate) : 0;

  return (
    <Card className={`h-100 shadow-sm ${styles.card}`}>

      <div className="position-relative">
        <Link to={`/offer/${offer.id}`}>
          <Card.Img variant="top" src={offer.imagePreview} className={styles.image} />
        </Link>
        <VendorButtons offer={offer} />
      </div>

      <Card.Body className="pt-4">

        <h6 className="mb-3">
          <Link to={`/offer/${offer.id}`} className="text-gray-900">{offer.title}</Link>
        </h6>

        {remainingDays > 0 ? (
          <p className="text-muted">
            <FontAwesomeIcon icon={RegularIcons.faClock} className="pe-1" />
            <Trans i18nKey="OfferCard.RemainingDays" count={remainingDays}>
              Ends in {{remainingDays}} days
            </Trans>
          </p>
        ) : ''}

        <Row>
          <Col>
            <p className="text-danger m-0 mt-1">
              <img src="/assets/images/icons/discount.svg" className={`${styles.discountIcon} img-fluid`} alt={t('OfferCard.Discount') || 'Discount'} />
              <Trans i18nKey="OfferCard.DiscountPercent" count={offer.discount}>
                {`{{offer.discount}} % OFF`}
              </Trans>
            </p>
          </Col>
          <Col className="text-end">
            <Link to={`/offer/${offer.id}`}  className="btn btn-light btn-sm border border-accent-2">
              {t('OfferCard.GetOfferButton')}
            </Link>
          </Col>
        </Row>

      </Card.Body>
    </Card>
  );
}

import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import * as SolidIcons from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Offer } from 'features/public/offers/Offer';
import { CardItem, CardItemTypeEnum } from 'types/CardItem';
import { Coupon } from 'types/Coupon';
import { Vendor } from 'types/Vendor';

import { Link } from 'components/helpers/RouterWrapper';
import { CardsCarousel } from 'components/cards/CardsCarousel/CardsCarousel';
import { OfferCard } from 'components/cards/OfferCard/OfferCard';
import { VendorCard } from 'components/cards/VendorCard/VendorCard';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import { fetchAllOffers, selectAll as selectAllOffers } from 'features/public/offers/offersSlice';
import { fetchAllVendors, selectAll as selectAllVendors } from 'features/vendors/vendorsSlice';

import styles from './HomePage.module.scss';

export function HomePage() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchAllOffers());
    dispatch(fetchAllVendors());
  }, [dispatch]);

  const offers: Offer[] = useAppSelector(selectAllOffers);
  const vendors: Vendor[] = useAppSelector(selectAllVendors);

  const coupons: Coupon[] = [
    {
      id: 1,
      title: "50% OFF",
      subtitle: "Get Flat 50% OFF On First Order",
      expiryDate: "2023-09-15T00:00:00Z",
      imagePreview: "",
    },
    {
      id: 2,
      title: "Buy 1 Get 1 Free",
      subtitle: "Get Flat 50% OFF On First Order",
      expiryDate: "2023-09-15T00:00:00Z",
      imagePreview: "",
    },
    {
      id: 3,
      title: "Free Burger",
      subtitle: "Get Flat 50% OFF On First Order",
      expiryDate: "2023-09-15T00:00:00Z",
      imagePreview: "",
    },
    {
      id: 4,
      title: "80% OFF",
      subtitle: "Get Flat 50% OFF On First Order",
      expiryDate: "2023-09-15T00:00:00Z",
      imagePreview: "",
    },
    {
      id: 5,
      title: "50% OFF",
      subtitle: "Get Flat 50% OFF On First Order",
      expiryDate: "2023-09-15T00:00:00Z",
      imagePreview: "",
    },
  ];

  const stores: Vendor[] = [
    {
      id: 1,
      title: "",
      titleFull: "Special Offer: Get Up to 50% Off On Web Hosting",
      type: "$80",
      imagePreview: "/assets/images/test/mcdonalds-510x300.jpg",
      addressShort: "",
    },
    {
      id: 2,
      title: "",
      titleFull: "New User - Get Flat Rs.100 OFF + Rs.300 Licious Cash",
      type: "$80",
      imagePreview: "/assets/images/test/mcdonalds-510x300.jpg",
      addressShort: "",
    },
    {
      id: 3,
      title: "",
      titleFull: "Coupon Code: Get Flat 50% Off On Online Food",
      type: "$80",
      imagePreview: "/assets/images/test/mcdonalds-510x300.jpg",
      addressShort: "",
    },
    {
      id: 4,
      title: "",
      titleFull: "New User - Get Flat Rs.100 OFF + Rs.300 Licious Cash",
      type: "$80",
      imagePreview: "/assets/images/test/mcdonalds-510x300.jpg",
      addressShort: "",
    },
  ];

  // TODO: check loading state and redirect to the corresponding error page in case of error

  return (
    <>

      <section className="section-padding">

        <Container>

          <Row>
            <Col lg={8} className="mx-auto pt-lg-5">

              <h1 className={`${styles.title} font-weight-bold mb-3 text-center text-gray-900`}>
                <Trans i18nKey="Pages.Home.SearchTitle">
                  Find Best Deals in
                  <span className="font-weight-light">Taldyqorgan</span>
                </Trans>
              </h1>

            </Col>
            <Col lg={7} className="mx-auto">

              <Form>
                <Row>

                  <Form.Group className="mb-3" as={Col} lg={3}>
                    <Form.Select className="border-accent-1 shadow-sm">
                      <option value="all"> {t('Search.All')}</option>
                      <option> {t('Search.Coupons')}</option>
                      <option> {t('Search.Offers')}</option>
                      <option> {t('Search.Vendors')}</option>
                      <option> {t('Search.Stores')}</option>
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className="mb-3" as={Col} lg={7}>
                    <Form.Control className="border-accent-1 shadow-sm" type="text" placeholder="Search for stores, coupons, &amp; offers…" />
                  </Form.Group>

                  <Form.Group className="mb-3" as={Col} lg={2}>
                    <Button className="shadow-sm w-100">
                      <FontAwesomeIcon icon={SolidIcons.faSearch} transform="shrink-3" className="text-white-50" />
                    </Button>
                  </Form.Group>

                </Row>
              </Form>

            </Col>
          </Row>

        </Container>

      </section>

      <section className="section-padding">

        <Container>

          <Row>
            <Col>
              <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h2 className="h5 mb-0 text-gray-900">{t('Pages.Home.TopOffers')}</h2>
                <Link to="/offers/" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
                  <span className="small">
                    <FontAwesomeIcon icon={SolidIcons.faEye} transform="shrink-3" className="text-white-50" />
                    {' '}
                    {t('Pages.Home.ViewAll')}
                  </span>
                </Link>
              </div>
            </Col>
          </Row>

          <Row className="g-4" xs={1} sm={2} md={3} lg={4} xl={4}>
            {offers.slice(0,4).map((offer, idx) => (
              <Col key={idx}>
                <OfferCard offer={offer} />
              </Col>
            ))}
          </Row>

        </Container>

      </section>

      <section className="section-padding">

        <Container>

          <Row>
            <Col>
              <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h2 className="h5 mb-0 text-gray-900">{t('Pages.Home.TopVendors')}</h2>
                <Link to="/stores/" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
                  <span className="small">
                    <FontAwesomeIcon icon={SolidIcons.faEye} transform="shrink-3" className="text-white-50" />
                    {' '}
                    {t('Pages.Home.ViewAll')}
                  </span>
                </Link>
              </div>
            </Col>
          </Row>

          <CardsCarousel items={vendors.map(
            vendor => new CardItem(CardItemTypeEnum.Vendor, vendor)
          )} />

        </Container>

      </section>

      <section className="section-padding">

        <Container>

          <Row>
            <Col>
              <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h2 className="h5 mb-0 text-gray-900">{t('Pages.Home.TopCoupons')}</h2>
                <Link to="/offers/" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
                  <span className="small">
                    <FontAwesomeIcon icon={SolidIcons.faEye} transform="shrink-3" className="text-white-50" />
                    {' '}
                    {t('Pages.Home.ViewAll')}
                  </span>
                </Link>
              </div>
            </Col>
          </Row>

          <CardsCarousel items={coupons.map(
            coupon => new CardItem(CardItemTypeEnum.Coupon, coupon)
          )} />

        </Container>

      </section>

      <section className="section-padding">

        <Container>

          <Row>
            <Col>
              <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h2 className="h5 mb-0 text-gray-900">{t('Pages.Home.TopStores')}</h2>
                <Link to="/stores/" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
                  <span className="small">
                    <FontAwesomeIcon icon={SolidIcons.faEye} transform="shrink-3" className="text-white-50" />
                    {' '}
                    {t('Pages.Home.ViewAll')}
                  </span>
                </Link>
              </div>
            </Col>
          </Row>

          <Row className="g-4" xs={1} sm={2} md={3} lg={4} xl={4}>
            {stores.slice(0,4).map((store, idx) => (
              <Col key={idx}>
                <VendorCard vendor={store} />
              </Col>
            ))}
          </Row>

        </Container>

      </section>

    </>
  );
}

import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { fetchAll, fetchOne } from './offersAPI';
import { Offer } from './Offer';

const offersAdapter = createEntityAdapter<Offer>({
  // Keep the "all IDs" array sorted based on offer titles
  //sortComparer: (a, b) => a.title.localeCompare(b.title),
});

export const fetchAllOffers = createAsyncThunk(
  'public/offers/fetchAll',
  async (_, { dispatch }) => {
    const response = await fetchAll();
    dispatch(setAllOffers(response.data.list as Offer[]));
  }
);

export const fetchOneOffer = createAsyncThunk(
  'public/offers/fetchOne',
  async (offerId: number, { dispatch }) => {
    const response = await fetchOne(offerId);
    dispatch(addOneOffer(response.data as Offer));
  }
);

export const offersSlice = createSlice({
  name: 'public/offers',
  initialState: offersAdapter.getInitialState(),
  reducers: {
    addOneOffer: offersAdapter.addOne,
    setAllOffers: offersAdapter.setAll,
  },
});

export const { addOneOffer, setAllOffers } = offersSlice.actions;

export const { selectAll, selectById } = offersAdapter.getSelectors<RootState>(
  (state: RootState) => state.public.offers
);

export default offersSlice.reducer;

import React from 'react';

import Badge from 'react-bootstrap/Badge';

import { Offer } from 'features/public/offers/Offer';

import styles from './VendorButtons.module.scss';

type VendorButtonsProps = {
  offer: Offer;
};

export function VendorButtons({ offer }: VendorButtonsProps) {
  return (
    <div className={styles.button}>
      {offer.vendorLogo ? (
        <>
          <img src={offer.vendorLogo} className="img-fluid rounded-circle border border-white border-3 shadow-lg" alt={offer.vendorName} />
          &nbsp;
        </>
      ) : ''}
      <Badge bg="gradient" pill className="border border-white border-3 shadow-sm">
        {offer.vendorName}
      </Badge>
    </div>
  );
}

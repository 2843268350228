import React from 'react';

import Card from 'react-bootstrap/Card';

import * as RegularIcons from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Coupon } from 'types/Coupon';

import styles from './CouponCard.module.scss';

type CouponCardProps = {
  coupon: Coupon;
};

export function CouponCard({ coupon }: CouponCardProps) {
  return (
    <Card className={`h-100 shadow-sm ${styles.card}`}>

      <Card.Body className="p-4 text-center">
        <div className={`mb-3 ${styles.img}`}></div>
        <h4 className="mt-1 h5 text-gray-900">{coupon.title}</h4>
        <h6 className="m-4 mb-4 mt-3 pb-2 text-secondary font-weight-normal">{coupon.subtitle}</h6>
        <p className="mb-0 text-gray-500">
          <FontAwesomeIcon icon={RegularIcons.faClock} className="pe-1" />
          Ends {new Date(coupon.expiryDate || 0).toLocaleDateString()}
        </p>
      </Card.Body>

    </Card>
  );
}

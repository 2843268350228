import {createAsyncThunk, createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { ILoginFormValues } from 'components/forms/LoginForm/types';
import { signIn } from './accountAPI';
import { AuthState, initialState, typeGuard } from './AuthState';

export const authenticate = createAsyncThunk(
  'account/signIn',
  async (credentials: ILoginFormValues, { dispatch, rejectWithValue }) => {
    try {
      const response = await signIn(credentials);

      if (!response.status) {
        throw new Error("API error", {
          cause: {
            code: 0,
            message: "Unexpected response from server"
          },
        });
      }

      if (response?.status !== 'ok') {
        throw new Error("API error", {
          cause: response?.errors[0],
        });
      }

      if (!typeGuard(response.data)) {
        throw new Error("API error", {
          cause: {
            code: 0,
            message: "Unexpected data from server"
          },
        });
      }

      dispatch(loginSuccess(response.data));
    } catch (err) {
      if (err instanceof Error) {
        return rejectWithValue(err.cause || {
          code: 0,
          message: err.message,
        });
      }
      return rejectWithValue(err);
    }
  }
);

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    loginSuccess: (state, action: PayloadAction<AuthState>) => {
      state.account = action.payload.account;
      state.expiryDate = action.payload.expiryDate;
      state.token = action.payload.token;
    },
    logout: () => initialState,
  },
});

export const { loginSuccess, logout } = accountSlice.actions;

export const selectAuthState = createSelector(
  [(state: RootState) => state.account],
  auth => auth,
);

export const selectCurrentAccount = createSelector(
  [(state: RootState) => state.account],
  auth => auth.account,
);

export default accountSlice.reducer;

import { combineReducers } from '@reduxjs/toolkit';

import accountReducer from 'features/account/accountSlice';
import counterReducer from 'features/counter/counterSlice';
import privateOffersReducer from 'features/private/offers/offersSlice';
import publicOffersReducer from 'features/public/offers/offersSlice';
import vendorsReducer from 'features/vendors/vendorsSlice';

export const rootReducer = combineReducers({
  account: accountReducer,
  private: combineReducers({
    offers: privateOffersReducer,
  }),
  public: combineReducers({
    offers: publicOffersReducer,
  }),
  vendors: vendorsReducer,

  // TODO: remove example stuff
  counter: counterReducer,
});

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Formik } from 'formik';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';

import { getLocationWithLang } from 'components/helpers/RouterWrapper';

import { Offer } from 'features/private/offers/Offer';

import { TFormikSubmissionHandler } from '../types';
import { IOfferEditFormValues } from './types';

import { TextInputGroup } from 'components/forms/subforms/TextInputGroup/TextInputGroup';

type OfferEditFormProps = {
  offer: Offer;
  navigateOnSuccess?: string;
};

export function OfferEditForm({ offer, navigateOnSuccess }: OfferEditFormProps) {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const [formProcessing, setFormProcessing] = useState(false);

  const initialValues: IOfferEditFormValues = offer;

  const handleSubmit: TFormikSubmissionHandler<IOfferEditFormValues> = (values: IOfferEditFormValues) => {
    setFormProcessing(true);

    // TODO: process the values
    console.log(values);

    toast.success("Saved successfully");

    setFormProcessing(false);

    if (navigateOnSuccess) {
      navigate(getLocationWithLang(navigateOnSuccess, i18n));
    }
  };

  return (
    <Formik onSubmit={handleSubmit}
            initialValues={initialValues}
    >
      {formik => (
        <Form onSubmit={formik.handleSubmit}>

          <TextInputGroup formik={formik} name="title" label="Title" colClassName="mb-2" />

          <TextInputGroup formik={formik} name="vendorName" label="Store" colClassName="mb-2" />

          <TextInputGroup formik={formik} name="discount" label="Discount" colClassName="mb-2" />

          <TextInputGroup formik={formik} name="description" label="Description" colClassName="mb-2" />

          <Button disabled={formProcessing}
                  size="lg"
                  type="submit"
                  variant="primary"
          >
            {formProcessing ? (
              <span className="me-2">
                  <Spinner animation="border" size="sm" />
                </span>
            ) : ''}
            Save
          </Button>

        </Form>
      )}
    </Formik>
  );
}

import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { fetchAll, fetchOne } from './vendorsAPI';
import { Vendor } from 'types/Vendor';

const vendorsAdapter = createEntityAdapter<Vendor>({
  // Keep the "all IDs" array sorted based on vendor titles
  //sortComparer: (a, b) => a.title.localeCompare(b.title),
});

export const fetchAllVendors = createAsyncThunk(
  'vendors/fetchAll',
  async (_, { dispatch }) => {
    const response = await fetchAll();
    dispatch(setAllVendors(response.data as Vendor[]));
  }
);

export const fetchOneVendor = createAsyncThunk(
  'vendors/fetchOne',
  async (vendorId: number, { dispatch }) => {
    const response = await fetchOne(vendorId);
    dispatch(addOneVendor(response.data as Vendor));
  }
);

export const vendorsSlice = createSlice({
  name: 'vendors',
  initialState: vendorsAdapter.getInitialState(),
  reducers: {
    addOneVendor: vendorsAdapter.addOne,
    setAllVendors: vendorsAdapter.setAll,
  },
});

export const { addOneVendor, setAllVendors } = vendorsSlice.actions;

export const { selectAll, selectById } = vendorsAdapter.getSelectors<RootState>(
  (state: RootState) => state.vendors
);

export default vendorsSlice.reducer;

import React from 'react';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

export function VendorManagePage() {
  return (
    <section className="section-padding">
      <Container>

        <Row>
          <Col>
            <h2>My Stores</h2>
          </Col>
        </Row>

        <Row>
          <Col>
            <p>TBD: My Stores List</p>
          </Col>
        </Row>

      </Container>
    </section>
  );
}

import React from 'react';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { defaultLanguage, languages } from 'app/conf';

import { TopNavbar } from './TopNavbar';
import { Footer } from './Footer';

export function Layout({ lang }: { lang?: string }) {
  const { i18n } = useTranslation();

  lang = lang || defaultLanguage;

  if (i18n.language !== lang && languages.includes(lang)) {
    i18n.changeLanguage(lang)
      .catch(() => console.error("Failed to set language"));
  }

  return (
    <>

      <TopNavbar />

      <Outlet />

      <Footer />

    </>
  );
}

import React from 'react';
import { matchPath, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Form from 'react-bootstrap/Form';

import { languages } from 'app/conf';

export function LangSelector() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const path = useLocation()?.pathname;

  const match = matchPath({
    path: "/:lang/*"
  }, path);

  const lang = match?.params?.lang;

  const relativePath = lang && languages.includes(lang) ? '/' + match?.params["*"] : path;

  return (
    <Form.Select value={i18n.language} size="sm" onChange={event => {
      navigate(`/${event.target.value}${relativePath}`);
    }}>
      {languages.map((lng, idx) => (
        <option value={lng} key={idx}>
          {t('i18n.LanguageNameShort', {lng, fallbackLng: lng, defaultValue: lng})}
        </option>
      ))}
    </Form.Select>
  );
}

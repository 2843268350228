import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { fetchAll, fetchOne } from './offersAPI';
import { Offer } from './Offer';

const offersAdapter = createEntityAdapter<Offer>({
  // Keep the "all IDs" array sorted based on offer titles
  //sortComparer: (a, b) => a.title.localeCompare(b.title),
});

export const fetchAllOffers = createAsyncThunk<
  void, undefined, { state: RootState }
>(
  'private/offers/fetchAll',
  async (_, { dispatch, getState }) => {
    const { account } = getState();

    if (account.token === null) {
      throw new Error("Auth error", {
        cause: {
          code: 0,
          message: "Auth data missed"
        },
      });
    }

    dispatch(loadingStarted());
    const response = await fetchAll(account.token);
    dispatch(loadingFinished());

    dispatch(setAllOffers(response.data.list as Offer[]));
  }
);

export const fetchOneOffer = createAsyncThunk<
  void, number, { state: RootState }
>(
  'private/offers/fetchOne',
  async (offerId: number, { dispatch, getState }) => {
    const { account } = getState();

    if (account.token === null) {
      throw new Error("Auth error", {
        cause: {
          code: 0,
          message: "Auth data missed"
        },
      });
    }

    dispatch(loadingStarted());
    const response = await fetchOne(account.token, offerId);
    dispatch(loadingFinished());

    dispatch(addOneOffer(response.data as Offer));
  }
);

export const deleteOneOffer = createAsyncThunk<
  void, number, { state: RootState }
>(
  'private/offers/deleteOne',
  async (offerId: number, { dispatch, getState }) => {
    const { account } = getState();

    if (account.token === null) {
      throw new Error("Auth error", {
        cause: {
          code: 0,
          message: "Auth data missed"
        },
      });
    }

    // TODO: make an API call here

    dispatch(removeOneOffer(offerId));
  }
);

export const offersSlice = createSlice({
  name: 'private/offers',
  initialState: offersAdapter.getInitialState({
    loading: 'idle',
  }),
  reducers: {
    addOneOffer: offersAdapter.addOne,
    removeOneOffer: offersAdapter.removeOne,
    setAllOffers: offersAdapter.setAll,
    loadingFinished: state => {
      state.loading = 'idle';
    },
    loadingStarted: state => {
      state.loading = 'pending';
    },
  },
});

export const {
  addOneOffer,
  removeOneOffer,
  setAllOffers,
  loadingFinished,
  loadingStarted,
} = offersSlice.actions;

export const { selectAll, selectById } = offersAdapter.getSelectors<RootState>(
  (state: RootState) => state.private.offers
);

export default offersSlice.reducer;

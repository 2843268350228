import { getVendorsData } from 'app/mock-data';
import { Vendor } from 'types/Vendor';

// A mock functions to mimic making an async request
export function fetchAll() {
  return new Promise<{ status: string, data: Vendor[] }>((resolve) =>
    setTimeout(() => {
      console.log("Development", process.env.NODE_ENV === 'development');
      console.log("[MOCK] All vendors request");
      resolve({
        status: 'ok',
        data: getVendorsData(),
      })
    }, 200)
  );
}

export function fetchOne(vendorId: number) {
  return new Promise<{ status: string, data: Vendor }>((resolve) =>
    setTimeout(() => {
      console.log("Development: ", process.env.NODE_ENV === 'development');
      console.log("[MOCK] One vendor request; id: ", vendorId);
      resolve({
        status: 'ok',
        data: getVendorsData().find(item => item.id === vendorId) as Vendor,
      })
    }, 200)
  );
}

import React from 'react';
import { useTranslation } from 'react-i18next';

import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import { NavLink } from 'components/helpers/RouterWrapper';
import { LangSelector } from 'components/LangSelector/LangSelector';

import { Account } from 'types/Account';

import { useAppSelector } from 'app/hooks';
import { selectCurrentAccount } from 'features/account/accountSlice';

import logo from './logo.svg';

export function TopNavbar() {
  const { t } = useTranslation();

  const account: Account|null = useAppSelector(selectCurrentAccount);

  return (
    <Navbar bg="light" expand="md" className="shadow-sm">
      <Container>

        <Navbar.Brand as={NavLink} to="/">
          <img src={logo} width={30} height={30} className="d-inline-block align-top" alt={process.env.REACT_APP_NAME} />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="top-navbar-nav" />

        <Navbar.Collapse id="top-navbar-nav">

          <Nav className="me-auto">
            <Nav.Link as={NavLink} to="/">{t('Navigation.Home')}</Nav.Link>
            <Nav.Link as={NavLink} to="/offers/">{t('Navigation.Offers')}</Nav.Link>
            <Nav.Link as={NavLink} to="/stores/">{t('Navigation.Vendors')}</Nav.Link>
            <Nav.Link as={NavLink} to="/offers/">{t('Navigation.Coupons')}</Nav.Link>
            <Nav.Link as={NavLink} to="/stores/">{t('Navigation.Stores')}</Nav.Link>
          </Nav>

          <Nav className="top-navbar-right">
            <Navbar.Text>
              <LangSelector />
            </Navbar.Text>

            {account ? (
              <Navbar.Text>
                <p className="px-2 pt-1 pb-0 m-0">Signed in as: <NavLink to="/account/">{account.username}</NavLink></p>
              </Navbar.Text>
            ) : (
              <Navbar.Text className={process.env.NODE_ENV === 'development' ? "" : "d-none"}>
                <p className="px-2 pt-1 pb-0 m-0">
                  <NavLink to="/login/">
                    {t('Navigation.SignIn')}
                  </NavLink>
                </p>
              </Navbar.Text>
            )}
          </Nav>

        </Navbar.Collapse>

      </Container>
    </Navbar>
  );
}

import React, { useEffect } from 'react';

import { useParams } from 'react-router-dom';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import * as RegularIcons from '@fortawesome/free-regular-svg-icons';
import * as SolidIcons from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Offer } from 'features/public/offers/Offer';

import { EntityId } from '@reduxjs/toolkit';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { fetchOneOffer, selectById } from 'features/public/offers/offersSlice';

import { GoogleMap } from 'components/GoogleMap/GoogleMap';
import { VendorButtons } from 'components/VendorButtons/VendorButtons';

import styles from './OfferViewPage.module.scss';

export function OfferViewPage() {
  const dispatch = useAppDispatch();

  let { offerId } = useParams();

  useEffect(() => {
    const id = parseInt(offerId || '0');
    dispatch(fetchOneOffer(id));
  }, [dispatch, offerId]);

  const offer: Offer = useAppSelector(state => selectById(state, offerId as EntityId))!;

  if (!offer) {
    // TODO: check loading state and redirect to the corresponding error page in case of error
    return (
      <div>404 Error</div>
    );
  }

  return (
    <>

      <section className="text-center">
        <img src={offer.imageHd} className="img-fluid" alt={offer.title} />
      </section>

      <section className="bg-accent-3">

        <Container>

          <Row>
            <Col className="position-relative">
              <VendorButtons offer={offer} />
            </Col>
          </Row>

          <Row className="py-4">
            <Col xl={12}>
              <h3 className="font-weight-bold text-white">{offer.title}</h3>
              {offer.subtitle ? (
                <h6 className="font-weight-light text-gray-400">{offer.subtitle}</h6>
              ) : ''}
            </Col>
          </Row>

        </Container>

      </section>

      <section className="section-padding">

        <Container>

          <Row className="pt-lg-4">
            <Col xl={8} sm={12}>

              <div className="bg-white rounded shadow-sm p-4 mb-4">
                <h5 className="mb-3 text-gray-900">About</h5>
                <div dangerouslySetInnerHTML={{__html: offer.description}} />
              </div>

              <div className="bg-white rounded shadow-sm p-4 mb-4 d-none">
                <h5 className="mb-3 text-gray-900">Offers</h5>
              </div>

              <div className="bg-white rounded shadow-sm p-4 mb-4">
                <h5 className="mb-3 text-gray-900">Address</h5>
                {offer.address ? (
                  <p>{offer.address}</p>
                ) : ''}
                {offer.phone ? (
                  <p className="mb-2">
                    <span className={`${styles.icon} fa-layers fa-fw fa-lg text-primary`}>
                      <FontAwesomeIcon icon={RegularIcons.faCircle} transform="shrink-3" />
                      <FontAwesomeIcon icon={SolidIcons.faPhone} transform="shrink-9" />
                    </span>
                    {offer.phone}
                  </p>
                ) : ''}
                {offer.email ? (
                  <p className="mb-2">
                    <span className={`${styles.icon} fa-layers fa-fw fa-lg text-primary`}>
                      <FontAwesomeIcon icon={SolidIcons.faEnvelope} transform="shrink-3" />
                    </span>
                    {offer.email}
                  </p>
                ) : ''}
                {offer.geoLocation ? (
                  <>
                    <hr />
                    <GoogleMap location={offer.geoLocation} />
                  </>
                ) : ''}
              </div>

            </Col>
            <Col xl={4} sm={12}>

              <div className="bg-white rounded shadow-sm p-4 mb-4 d-none">
                <h5 className="mb-3 text-gray-900">Top Offers</h5>
              </div>

              <div className="bg-white rounded shadow-sm p-4 mb-4">
                <h5 className="mb-3 text-gray-900">Terms and Conditions</h5>
                <p>Please read these terms and conditions carefully before using our online shop. By using our online shop, you agree to be bound by these terms and conditions.</p>
                <ol>
                  <li><strong>Product Information</strong>
                    <p>We make every effort to ensure that product information and pricing are accurate and up to date. However, we do not guarantee the accuracy of such information.</p>
                  </li>
                  <li><strong>Intellectual Property</strong>
                    <p>All content on our online shop, including but not limited to images, text, and logos, are the property of our online shop or its affiliates and are protected by copyright laws. You may not use any content from our online shop without our express written permission.</p>
                  </li>
                  <li><strong>Limitation of Liability</strong>
                    <p>Our online shop shall not be liable for any direct, indirect, incidental, special, or consequential damages arising from the use of our online shop or the purchase of any products from our online shop.</p>
                  </li>
                  <li><strong>Changes to Terms and Conditions</strong>
                    <p>We reserve the right to modify these terms and conditions at any time without prior notice. Your continued use of our online shop constitutes your agreement to be bound by the modified terms and conditions.</p>
                  </li>
                </ol>
                <p>If you have any questions or concerns about these terms and conditions, please contact us before making a purchase.</p>
              </div>

            </Col>
          </Row>

        </Container>

      </section>

    </>
  );
}

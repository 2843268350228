import React from 'react';
import { useTranslation } from 'react-i18next';

import Badge from 'react-bootstrap/Badge';
import Card from 'react-bootstrap/Card';

import { Vendor } from 'types/Vendor';

import { Link } from 'components/helpers/RouterWrapper';

import styles from './VendorCard.module.scss';

type VendorCardProps = {
  vendor: Vendor;
};

export function VendorCard({ vendor }: VendorCardProps) {
  const { t } = useTranslation();

  return (
    <Card className={`h-100 shadow-sm ${styles.card}`}>

      <div className="position-relative">
        <Link to={`/store/${vendor.id}`}>
          <Card.Img variant="top" src={vendor.imagePreview} className={styles.image} />
        </Link>
        <div className={`text-white ${styles.shape}`}>
          <svg viewBox="0 0 2880 480" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M2160 0C1440 240 720 240 720 240H0V480H2880V0H2160Z"></path>
          </svg>
        </div>
      </div>

      <Card.Body>

        <h6 className="mb-0">
          <Link to={`/store/${vendor.id}`} className="text-gray-900">{vendor.titleFull}</Link>
        </h6>

        {vendor.addressShort ? (
          <p className="text-gray-500 mb-2">{vendor.addressShort}</p>
        ) : ''}

        <p className="text-gray-900 mb-0">{vendor.type}</p>

      </Card.Body>

      <Card.Footer className="bg-white border-top p-3">
        <div className="bg-gray-100 text-gray-600 font-size-xs p-1 rounded-pill">
          <Badge bg="danger" className={`font-size-xs font-weight-normal text-uppercase px-2 mx-1 me-2 ${styles.badge}`}>
            {t('VendorCard.OfferBadge')}
          </Badge>
          Flat 10% on all Stores
        </div>
      </Card.Footer>

    </Card>
  );
}

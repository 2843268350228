import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { Flip, ToastContainer } from 'react-toastify';
import LoadingBar from 'react-top-loading-bar';
import ScrollToTop from 'components/helpers/ScrollToTop';

import { Layout } from 'components/pages/Layouts/Layout';
import { ProtectedLayout } from 'components/pages/Layouts/ProtectedLayout';

import { AccountPage } from 'components/pages/account/AccountPage';
import { HomePage } from 'components/pages/home/HomePage';
import { LoginPage } from 'components/pages/login/LoginPage';
import { OfferEditPage } from 'components/pages/offer/OfferEditPage';
import { OfferListPage } from 'components/pages/offer/OfferListPage';
import { OfferManagePage } from 'components/pages/offer/OfferManagePage';
import { OfferViewPage } from 'components/pages/offer/OfferViewPage';
import { VendorListPage } from 'components/pages/vendors/VendorListPage';
import { VendorManagePage } from 'components/pages/vendors/VendorManagePage';
//import { Counter } from 'features/counter/Counter';

import { languages } from 'app/conf';
//import { useAppDispatch } from 'app/hooks';

import 'react-multi-carousel/lib/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

function App() {
  //const dispatch = useAppDispatch();

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    // TODO: load account data
    if (!isLoaded) {
      setTimeout(() => {
        setProgress(100);
        setIsLoaded(true);
      }, 200);
    }

    // TODO: implement Dark Mode
    //document.documentElement.setAttribute("darkMode", "")
  });

  const primaryColor = window.getComputedStyle(document.documentElement).getPropertyValue('--bs-primary');

  return (
    <BrowserRouter>
      {/* TODO: use <ScrollRestoration /> component from react-router-dom */}
      <ScrollToTop />
      <LoadingBar color={primaryColor} progress={progress} onLoaderFinished={() => setProgress(0)} />
      <ToastContainer transition={Flip} />

      <Routes>
        {languages.concat('').map((lang: string, index: number) => (
          <React.Fragment key={index}>
            <Route path={lang ? `/${lang}/` : '/'} element={<Layout lang={lang} />}>
              <Route index element={<HomePage />} />
              <Route path="login/" element={<LoginPage />} />
              <Route path="offers/" element={<OfferListPage />} />
              <Route path="offer/:offerId" element={<OfferViewPage />} />
              <Route path="stores/" element={<VendorListPage />} />
              <Route path="account/" element={<ProtectedLayout />}>
                <Route index element={<AccountPage />} />
                <Route path="offers/" element={<OfferManagePage />} />
                <Route path="offer/:offerId" element={<OfferEditPage />} />
                <Route path="stores/" element={<VendorManagePage />} />
              </Route>
            </Route>
          </React.Fragment>
        ))}
        {/* TODO: handle missed pages with <Route path="*" element={<NotFoundPage />} /> */}
      </Routes>

    </BrowserRouter>
  );
/*
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <Counter />
          <p>
            Edit <code>src/App.tsx</code> and save to reload.
          </p>
          <span>
            <span>Learn </span>
            <a
              className="App-link"
              href="https://reactjs.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              React
            </a>
            <span>, </span>
            <a
              className="App-link"
              href="https://redux.js.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Redux
            </a>
            <span>, </span>
            <a
              className="App-link"
              href="https://redux-toolkit.js.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Redux Toolkit
            </a>
            ,<span> and </span>
            <a
              className="App-link"
              href="https://react-redux.js.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              React Redux
            </a>
          </span>
        </header>
*/
}

export default App;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import Button from 'react-bootstrap/Button';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';

import { TFormikSubmissionHandler } from '../types';
import { ILoginFormValues } from './types';

// Formik initial values
const initialValues = {
  username: "",
  password: "",
} as ILoginFormValues;

// Formik validation schema
const validationSchema = Yup.object({
  username: Yup.string()
    .email('Pages.Login.WrongEmailError')
    .required('Pages.Login.EmailRequiredError'), //
  password: Yup.string()
    .required('Pages.Login.PasswordRequiredError'),
});

type LoginFormProps = {
  handleSubmit: TFormikSubmissionHandler<ILoginFormValues>;
};

export function LoginForm({ handleSubmit }: LoginFormProps) {
  const { t } = useTranslation();

  const renderField = (
    formik: FormikProps<ILoginFormValues>,
    fieldName: keyof ILoginFormValues,
    fieldType: string,
    labelLocaleKey: string,
  ) => (
    <FloatingLabel label={t(labelLocaleKey)} className="mb-4">

      <Form.Control {...formik.getFieldProps(fieldName)}
                    type={fieldType}
                    placeholder={t('Pages.Login.EmailLabel') || ""}
                    isInvalid={!!(formik.touched[fieldName] && formik.errors[fieldName])}
      />

      <Form.Control.Feedback type="invalid" className="position-absolute">
        {t(formik.errors[fieldName] as string)}
      </Form.Control.Feedback>

    </FloatingLabel>
  );

  return (
    <Formik onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
    >
      {formik => (
        <Form className="bg-accent-2 p-4 rounded-3" onSubmit={formik.handleSubmit}>

          <h2 className="mb-4">{t('Pages.Login.SignIn')}</h2>

          {renderField(formik, 'username', 'email', 'Pages.Login.EmailLabel')}

          {renderField(formik, 'password', 'password', 'Pages.Login.PasswordLabel')}

          <Form.Group className="d-flex">
            <Button variant="primary" type="submit" size="lg" className="ms-auto">
              {t('Pages.Login.LogInButton')}
            </Button>
          </Form.Group>

        </Form>
      )}
    </Formik>
  );
}

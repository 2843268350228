
export function getOffersData() {
  return [
    {
      id: 1,
      title: "Start the day with Amazon",
      subtitle: "The quick brown fox jumps over a lazy dog",
      vendorName: "Amazon",
      vendorLogo: "/assets/images/test/logo-amazon.png",
      imageHd: "/assets/images/test/314-1440x450.jpg",
      imagePreview: "/assets/images/test/314-510x300.jpg",
      discount: 50,
      expiryDate: "2023-03-15T20:00:00Z",
      description:
        "<p>Introducing the latest must-have product from Amazon: the Echo Dot!</p>\n" +
        "<p>Are you tired of constantly reaching for your phone to play music or answer calls? Look no further than the Echo Dot, the solution you've been searching for! With its hands-free voice control, this device will make your life so much easier.</p>\n" +
        "<p>Advantages of the Echo Dot:</p>\n" +
        "<ul>\n" +
        "  <li>Hands-free voice control for convenient use</li>\n" +
        "  <li>Compact design for easy placement in any room</li>\n" +
        "  <li>Easy set-up for quick and hassle-free installation</li>\n" +
        "  <li>Connects to other smart devices for seamless control of your home</li>\n" +
        "  <li>Amazing sound quality for its small size</li>\n" +
        "  <li>Compatible with popular music streaming services like Spotify and Apple Music</li>\n" +
        "  <li>Allows you to make and receive calls without picking up your phone</li>\n" +
        "</ul>\n" +
        "<p>Don't just take our word for it. Customers are raving about the Echo Dot, with reviews like \"I absolutely love my Echo Dot. It's like having a personal assistant in my house!\" and \"The sound quality is amazing for such a small device.\" Join the thousands of satisfied customers who have already upgraded their home with the Echo Dot.</p>\n" +
        "<p>Order now and experience the convenience of hands-free voice control for yourself! With fast and reliable shipping from Amazon, you can start enjoying the benefits of the Echo Dot in no time.</p>\n",
      address: "Rozybakiev Street 247А, Almaty 050060, Kazakhstan",
      phone: "+7 777 123-45-67",
      email: "sales@domain.tld",
      geoLocation: {
        latitude: 43.2020,
        longitude: 76.8920,
      },
    },
    {
      id: 2,
      title: "iPhone Makes Everything Better",
      subtitle: "The quick brown fox jumps over a lazy dog",
      vendorName: "Apple",
      vendorLogo: "/assets/images/test/logo-apple.png",
      imageHd: "/assets/images/test/163-1440x450.jpg",
      imagePreview: "/assets/images/test/163-510x300.jpg",
      discount: 10,
      expiryDate: "2023-03-18T20:00:00Z",
      description:
        "<p>Introducing the latest innovation from Apple: the iPhone 13!</p>\n" +
        "<p>Get ready to experience the power and beauty of the iPhone 13. This device is packed with features that will take your mobile experience to the next level.</p>\n" +
        "<p>Benefits of the iPhone 13:</p>\n" +
        "<ul>\n" +
        "  <li>Faster performance and improved battery life with the new A15 Bionic chip.</li>\n" +
        "  <li>Stunning Super Retina XDR display that makes your photos and videos look more vibrant than ever.</li>\n" +
        "  <li>Dual-camera setup that allows you to take stunning photos and videos in any lighting condition.</li>\n" +
        "  <li>Night mode feature that enables you to capture incredible shots even in low light environments.</li>\n" +
        "  <li>5G capability that connects you to the internet at lightning-fast speeds, making streaming and downloading content a breeze.</li>\n" +
        "  <li>Sleek design that has a premium look and feel and is available in a range of stunning colors to suit your personal style.</li>\n" +
        "  <li>Advanced privacy features to help keep your personal information secure.</li>\n" +
        "  <li>Compatibility with a range of accessories that make your iPhone 13 even more versatile.</li>\n" +
        "</ul>\n" +
        "<p>But don't just take our word for it. Customers are raving about the iPhone 13, with reviews like \"This is the best iPhone yet!\" and \"The camera is amazing and the speed is unreal.\" Join the millions of satisfied customers who have already upgraded their mobile experience with the iPhone 13.</p>\n" +
        "<p>Order now and experience the power and beauty of the iPhone 13 for yourself. With fast and reliable shipping from Apple, you can start enjoying the benefits of this incredible device in no time.</p>",
      phone: "+62 361 123-45-67",
      email: "sales@domain.tld",
      geoLocation: {
        latitude: -8.8500,
        longitude: 115.1617,
      },
    },
    {
      id: 3,
      title: "All you need is new Pixel phone",
      subtitle: "The quick brown fox jumps over a lazy dog",
      vendorName: "Google",
      vendorLogo: "/assets/images/test/logo-google.png",
      imageHd: "/assets/images/test/454-1440x450.jpg",
      imagePreview: "/assets/images/test/454-510x300.jpg",
      discount: 20,
      expiryDate: "2023-03-31T20:00:00Z",
      description: "",
      geoLocation: {
        latitude: 48.8629,
        longitude: 2.2873,
      },
    },
    {
      id: 4,
      title: "Microsoft Windows - a safe place in an unsafe world!",
      subtitle: "The quick brown fox jumps over a lazy dog",
      vendorName: "Microsoft",
      vendorLogo: "/assets/images/test/logo-microsoft.png",
      imageHd: "/assets/images/test/674-1440x450.jpg",
      imagePreview: "/assets/images/test/674-510x300.jpg",
      discount: 30,
      expiryDate: "2023-03-11T20:00:00Z",
      description: "",
    },
    {
      id: 5,
      title: "I'm lovin' it",
      subtitle: "The quick brown fox jumps over a lazy dog",
      vendorName: "McDonald's",
      vendorLogo: undefined,
      imageHd: "/assets/images/test/744-1440x450.jpg",
      imagePreview: "/assets/images/test/744-510x300.jpg",
      discount: 40,
      expiryDate: "2023-03-15T00:00:00Z",
      description: "",
    },
    {
      id: 6,
      title: "Full Self-Driving Technology",
      subtitle: "The quick brown fox jumps over a lazy dog",
      vendorName: "Tesla",
      vendorLogo: undefined,
      imageHd: "/assets/images/test/838-1440x450.jpg",
      imagePreview: "/assets/images/test/838-510x300.jpg",
      discount: 15,
      expiryDate: "2023-03-10T00:00:00Z",
      description: "",
    },
    {
      id: 7,
      title: "Making the Most of Right Now",
      subtitle: "The quick brown fox jumps over a lazy dog",
      vendorName: "Tiktok",
      vendorLogo: undefined,
      imageHd: "/assets/images/test/5-1440x450.jpg",
      imagePreview: "/assets/images/test/5-510x300.jpg",
      discount: 60,
      description: "",
    },
    {
      id: 8,
      title: "Everywhere you want to be",
      subtitle: "The quick brown fox jumps over a lazy dog",
      vendorName: "Visa",
      vendorLogo: undefined,
      imageHd: "/assets/images/test/872-1440x450.jpg",
      imagePreview: "/assets/images/test/872-510x300.jpg",
      discount: 5,
      description: "",
    },
    {
      id: 9,
      title: "С iPhone всё становится лучше!",
      vendorName: "Apple",
      vendorLogo: "/assets/images/test/logo-apple.png",
      imageHd: "/assets/images/test/163-1440x450.jpg",
      imagePreview: "/assets/images/test/163-510x300.jpg",
      discount: 10,
      description:
        "<p>Новый iPhone 13 - это превосходный выбор для тех, кто ищет мощный и высококачественный смартфон. Вот некоторые из его преимуществ:</p>\n" +
        "<ul>\n" +
        "  <li><strong>Улучшенный процессор</strong> - iPhone 13 оснащен новым A15 Bionic процессором, который обеспечивает еще большую производительность и эффективность.</li>\n" +
        "  <li><strong>Превосходный дисплей</strong> - смартфон оснащен 6.1-дюймовым Super Retina XDR дисплеем с технологией ProMotion и высоким разрешением, который обеспечивает яркие и четкие изображения.</li>\n" +
        "  <li><strong>Улучшенная камера</strong> - iPhone 13 имеет улучшенную камеру с более большой диафрагмой и возможностью записи видео в формате ProRes.</li>\n" +
        "  <li><strong>Большой выбор цветов и хранения</strong> - iPhone 13 доступен в разных цветах и вариантах хранения, чтобы вы могли выбрать оптимальную конфигурацию для своих нужд.</li>\n" +
        "  <li><strong>Высокая надежность и безопасность</strong> - iPhone 13 обеспечивает высокую степень безопасности и защиту данных, благодаря функциям, таким как Touch ID, Face ID, и встроенная защита от воды и пыли.</li>\n" +
        "</ul>\n" +
        "<p>Не упустите возможность получить лучший опыт использования смартфона - купите новый iPhone 13 уже сегодня!</p>",
    },
    {
      id: 10,
      title: "Күнді Amazon-дан бастаңыз",
      vendorName: "Amazon",
      vendorLogo: "/assets/images/test/logo-amazon.png",
      imageHd: "/assets/images/test/314-1440x450.jpg",
      imagePreview: "/assets/images/test/314-510x300.jpg",
      discount: 50,
      description:
        "<p>Амазоннан ең соңғы болуы керек өнім: Эхо нүктесі!</p>\n" +
        "<p>Музыка ойнату немесе қоңырауларға жауап беру үшін телефонға үнемі қол созудан шаршадыңыз ба? Echo Dot-тен басқаны іздемеңіз, сіз іздеген шешім! Оның дауыспен басқаруымен бұл құрылғы сіздің өмір әлдеқайда жеңіл.</p>\n" +
        "<p>Жаңғырық нүктесінің артықшылықтары:</p>\n" +
        "<ul>\n" +
        " <li>Ыңғайлы пайдалану үшін қолдарсыз дауыстық басқару</li>\n" +
        " <li>Кез келген бөлмеде оңай орналастыруға арналған ықшам дизайн</li>\n" +
        " <li>Жылдам және қиындықсыз орнату үшін оңай орнату</li>\n" +
        " <li>Үйіңізді үздіксіз басқару үшін басқа смарт құрылғыларға қосылады</li>\n" +
        " <li>Кішкентай өлшемі үшін керемет дыбыс сапасы</li>\n" +
        " <li>Spotify және Apple Music сияқты танымал музыкалық ағындық қызметтермен үйлесімді</li>\n" +
        " <li>Телефонды көтермей-ақ қоңырау шалуға және қабылдауға мүмкіндік береді</li>\n" +
        "</ul>\n" +
        "<p>Бұл үшін жай ғана біздің сөзімізді қабылдамаңыз. Тұтынушылар \"Жаңғырық нүктесін\" мақтап жатыр, оның пікірлері \"Мен Эхо нүктесін өте жақсы көремін. Менің үйімде жеке көмекшім бар сияқты!\" және \"Дыбыс сапасы осындай кішкентай құрылғы үшін керемет\". Үйлерін Echo Dot көмегімен жаңартып қойған мыңдаған қанағаттанған тұтынушыларға қосылыңыз.</p>\n" +
        "<p>Қазір тапсырыс беріп, дауыспен басқарудың ыңғайлылығын сезініңіз! Amazon-дан жылдам және сенімді жеткізу арқылы сіз Echo Dot мүмкіндіктерін қысқа уақытта пайдалана бастай аласыз.</p>\n",
      address: "Rozybakiev Street 247А, Almaty 050060, Kazakhstan",
      geoLocation: {
        latitude: 43.2020,
        longitude: 76.8920,
      },
    },
  ];
}

export function getVendorsData() {
  return [
    {
      id: 1,
      title: "Amazon",
      titleFull: "Amazon.com, Inc.",
      type: "Technology company",
      imagePreview: "/assets/images/test/amazon-510x300.jpg",
      addressShort: "Seattle, Washington, United States",
    },
    {
      id: 2,
      title: "Apple",
      titleFull: "Apple Inc.",
      type: "Technology company",
      imagePreview: "/assets/images/test/apple-510x300.jpg",
      addressShort: "Cupertino, California, United States",
    },
    {
      id: 3,
      title: "Google",
      titleFull: "Google LLC",
      type: "Technology company",
      imagePreview: "/assets/images/test/google-510x300.jpg",
      addressShort: "Mountain View, California, United States",
    },
    {
      id: 4,
      title: "Microsoft",
      titleFull: "Microsoft Corporation",
      type: "Technology company",
      imagePreview: "/assets/images/test/microsoft-510x300.jpg",
      addressShort: "Redmond, Washington, United States",
    },
    {
      id: 5,
      title: "Tesla",
      titleFull: "Tesla, Inc.",
      type: "Automotive company",
      imagePreview: "/assets/images/test/tesla-510x300.jpg",
      addressShort: "Austin, Texas, United States",
    },
  ];
}


export const ITEMS_IN_ROW = {
  xs: 1,
  sm: 2,
  md: 3,
  lg: 4,
  xl: 4,
  xxl: 4,
  xxxl: 5,
};

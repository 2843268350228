import { Coupon } from './Coupon';
import { Offer } from './Offer';
import { Vendor } from './Vendor';

export enum CardItemTypeEnum {
  Coupon = "coupon",
  Offer = "offer",
  Vendor = "vendor",
}

export type CardItemTypeType = Coupon | Offer | Vendor;

export class CardItem {
  type!: CardItemTypeEnum;
  item!: CardItemTypeType;

  constructor(type: CardItemTypeEnum, item: CardItemTypeType) {
    this.type = type;
    this.item = item;
  }
}

import React from 'react';

import { GoogleMap as Map, MarkerF, useLoadScript } from '@react-google-maps/api';

import Spinner from 'react-bootstrap/Spinner';

import { GeoLocation } from 'types/GeoLocation';

const mapContainerStyle = {
  width: '100%',
  height: '50vh',
};

type GoogleMapProps = {
  location: GeoLocation;
};

const GoogleMap = React.memo(function GoogleMap({ location }: GoogleMapProps) {
  const googleMapsApiKey: string = typeof process.env.REACT_APP_GOOGLE_MAPS_API_KEY === 'string' ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY : "";

  const marker = {
    lat: location.latitude,
    lng: location.longitude,
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey,
  });

  if (googleMapsApiKey.length === 0 || loadError) {
    return (
      <div>Map cannot be loaded right now, sorry.</div>
    );
  }

  if (!isLoaded) {
    return (
      <Spinner animation="border" role="status" variant="secondary">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  }

  return (
    <Map center={marker}
         mapContainerStyle={mapContainerStyle}
         zoom={14}
    >
      <MarkerF position={marker} />
    </Map>
  );
});

export { GoogleMap };

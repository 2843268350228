import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import { Offer } from 'features/public/offers/Offer';
import { OfferCard } from 'components/cards/OfferCard/OfferCard';

import { useAppSelector, useAppDispatch } from 'app/hooks';
import { fetchAllOffers, selectAll } from 'features/public/offers/offersSlice';

import { ITEMS_IN_ROW } from 'components/constants';

export function OfferListPage() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchAllOffers());
  }, [dispatch]);

  const offers: Offer[] = useAppSelector(selectAll);

  // TODO: check loading state and redirect to the corresponding error page in case of error

  return (
    <section className="section-padding">

      <Container>

        <Row>
          <Col>
            <h2 className="h5 mb-4 text-gray-900">{t('Pages.Offers.ListTitle')}</h2>
          </Col>
        </Row>

        <Row className="g-4"
             xs={ITEMS_IN_ROW.xs}
             sm={ITEMS_IN_ROW.sm}
             md={ITEMS_IN_ROW.md}
             lg={ITEMS_IN_ROW.lg}
             xl={ITEMS_IN_ROW.xl}
        >
          {offers.map((offer, idx) => (
            <Col key={idx}>
              <OfferCard offer={offer} />
            </Col>
          ))}
        </Row>

      </Container>

    </section>
  );
}

import React from 'react';
import { FormikProps } from 'formik';

import Col from 'react-bootstrap/Col';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';

type TextInputGroupProps<Values> = {
  formik: FormikProps<Values>;
  name: string;
  label: string;
  placeholder?: string;
  md?: number;
  colClassName?: string | undefined;
};

export function TextInputGroup<Values>({ formik, name, label, placeholder, md, colClassName }: TextInputGroupProps<Values>) {
  const meta = formik.getFieldMeta(name);
  const isInvalid: boolean = !!(meta.touched && meta.error);

  return (
    <Form.Group as={Col} md={md} controlId={name} className={colClassName}>

      <FloatingLabel label={label} className="mb-4">

        <Form.Control {...formik.getFieldProps(name)}
                      isInvalid={isInvalid}
                      placeholder={placeholder || label}
                      type="text"
        />

        <Form.Control.Feedback type="invalid">
          {meta.error}
        </Form.Control.Feedback>

      </FloatingLabel>

    </Form.Group>
  );
}

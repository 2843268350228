import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { defaultLanguage } from './conf';

i18n
  .use(initReactI18next)
  .init({
    // TODO: move out locales to external files. See: https://github.com/i18next/i18next-http-backend
    resources: {
      en: {
        translation: {
          "errors": {
            "WrongCredentials": "The email address or password you entered isn't correct",
          },
          "i18n": {
            "LanguageNameShort": "Eng",
          },
          "Navigation": {
            "AboutUs": "About Us",
            "Contact": "Contact",
            "Coupons": "Coupons",
            "FAQ": "FAQ",
            "Home": "Home",
            "Offers": "Offers",
            "SignIn": "Sign in",
            "Stores": "Restaurant",
            "TermsAndConditions": "Terms & Conditions",
            "Vendors": "Stores",
          },
          "OfferCard": {
            "Discount": "Discount",
            "DiscountPercent": "{{count}}% OFF",
            "GetOfferButton": "Get Offer",
            "RemainingDays": "Ends in {{count}} days",
            "RemainingDays_one": "Ends in {{count}} day",
          },
          "Pages": {
            "Home": {
              "SearchTitle": "Find Best Deals in <1>Taldyqorgan</1>",
              "TopOffers": "Top Offers",
              "TopVendors": "Top Stores",
              "TopCoupons": "Top Coupons",
              "TopStores": "Top Restaurant",
              "ViewAll": "View All",
            },
            "Login": {
              "EmailLabel": "Email address",
              "EmailRequiredError": "Email is a required field",
              "LogInButton": "Log in",
              "PasswordLabel": "Password",
              "PasswordRequiredError": "Password is a required field",
              "SignIn": "Sign in",
              "WrongEmailError": "Wrong email address",
            },
            "Offers": {
              "ListTitle": "All Product Deals",
            },
          },
          "Search": {
            "All": "All",
            "Coupons": "Coupons",
            "Offers": "Offers",
            "Stores": "Restaurant",
            "Vendors": "Stores",
          },
          "VendorCard": {
            "OfferBadge": "Offer",
          },
        },
      },
      kz: {
        translation: {
          "errors": {
            "WrongCredentials": "Сіз енгізген электрондық пошта мекенжайы немесе құпия сөз дұрыс емес",
          },
          "i18n": {
            "LanguageNameShort": "Қаз",
          },
          "Navigation": {
            "AboutUs": "Біз туралы",
            "Contact": "Контактілер",
            "Coupons": "Промокодтар",
            "FAQ": "FAQ",
            "Home": "Басты",
            "Offers": "Жеңілдіктер",
            "SignIn": "Кіру",
            "Stores": "Тамақ",
            "TermsAndConditions": "Ережелері",
            "Vendors": "Компаниялар",
          },
          "OfferCard": {
            "Discount": "Жеңілдік",
            "DiscountPercent": "{{count}}% жеңілдік",
            "GetOfferButton": "Ұсыныс алу",
            "RemainingDays": "{{count}} күннен кейін аяқталады",
          },
          "Pages": {
            "Home": {
              "SearchTitle": "<1>Талдықорғанда</1> тамаша ұсыныстар табыңыз",
              "TopOffers": "Топ Дисконт",
              "TopVendors": "Топ Компаниялар",
              "TopCoupons": "Топ Промокодтар",
              "TopStores": "Топ Тамақ",
              "ViewAll": "Барлық",
            },
            "Login": {
              "EmailLabel": "Электрондық пошта",
              "EmailRequiredError": "Электрондық пошта міндетті өріс болып табылады",
              "LogInButton": "Кіру",
              "PasswordLabel": "Құпия сөз",
              "PasswordRequiredError": "Құпия сөз - міндетті өріс",
              "SignIn": "Кіру",
              "WrongEmailError": "Қате электрондық пошта мекенжайы",
            },
            "Offers": {
              "ListTitle": "Барлық Жеңілдіктер",
            },
          },
          "Search": {
            "All": "Барлық",
            "Coupons": "Промокодтар",
            "Offers": "Жеңілдіктер",
            "Stores": "Тамақ",
            "Vendors": "Компаниялар",
          },
          "VendorCard": {
            "OfferBadge": "Жеңілдік",
          },
        },
      },
      ru: {
        translation: {
          "errors": {
            "WrongCredentials": "Адрес электронной почты или пароль, которые вы ввели, неверны",
          },
          "i18n": {
            "LanguageNameShort": "Рус",
          },
          "Navigation": {
            "AboutUs": "О проекте",
            "Contact": "Контакты",
            "Coupons": "Промокоды",
            "FAQ": "FAQ",
            "Home": "Главная",
            "Offers": "Скидки",
            "SignIn": "Войти",
            "Stores": "Еда",
            "TermsAndConditions": "Правила сайта",
            "Vendors": "Компании",
          },
          "OfferCard": {
            "Discount": "Скидка",
            "DiscountPercent": "Скидка {{count}}%",
            "GetOfferButton": "Получить",
            "RemainingDays": "Заканчивается через {{count}} дней",
            "RemainingDays_few": "Заканчивается через {{count}} дня",
            "RemainingDays_one": "Заканчивается через {{count}} день",
          },
          "Pages": {
            "Home": {
              "SearchTitle": "Находите выгодные акции в <1>Талдыкоргане</1>",
              "TopOffers": "Топ Скидок",
              "TopVendors": "Топ Компаний",
              "TopCoupons": "Топ Промокодов",
              "TopStores": "Топ Еды",
              "ViewAll": "Смотреть все",
            },
            "Login": {
              "EmailLabel": "Электронная почта",
              "EmailRequiredError": "Электронная почта является обязательным полем",
              "LogInButton": "Войти",
              "PasswordLabel": "Пароль",
              "PasswordRequiredError": "Пароль является обязательным полем",
              "SignIn": "Вход",
              "WrongEmailError": "Неверный адрес электронной почты",
            },
            "Offers": {
              "ListTitle": "Все Скидки",
            },
          },
          "Search": {
            "All": "Все",
            "Coupons": "Промокоды",
            "Offers": "Скидки",
            "Stores": "Еда",
            "Vendors": "Компании",
          },
          "VendorCard": {
            "OfferBadge": "Скидка",
          },
        },
      },
    },
    lng: defaultLanguage,
    fallbackLng: "en",

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

export default i18n;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import { getLocationWithLang } from 'components/helpers/RouterWrapper';

import { LoginForm } from 'components/forms/LoginForm/LoginForm';
import { ILoginFormValues } from 'components/forms/LoginForm/types';

import { PayloadAction } from '@reduxjs/toolkit';
import { useAppDispatch } from 'app/hooks';
import { authenticate } from 'features/account/accountSlice';

export function LoginPage() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const handleSubmit = (values: ILoginFormValues) => {
    dispatch(authenticate(values))
      .then((result: PayloadAction<any>) => {
        if (result?.type === "account/signIn/rejected") {

          switch (result?.payload?.code) {
            case 401:
              toast.error(t('errors.WrongCredentials'));
              break;
            default:
              console.error(result?.payload);
              toast.error("Something went wrong"); // TODO: localize error message
          }

          return;
        }

        navigate(getLocationWithLang('/account/', i18n));
      })
      .catch(error => {
        console.log(error);
        toast.error("Something went wrong"); // TODO: localize error message
      });
  };

  return (
    <section className="section-padding">
      <Container>
        <Row>
          <Col sm={10} lg={8} xxl={5} className="mx-auto p-lg-5">

            <LoginForm handleSubmit={handleSubmit} />

          </Col>
        </Row>
      </Container>
    </section>
  );
}

import React from 'react';
import { useTranslation } from 'react-i18next';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import { Link } from 'components/helpers/RouterWrapper';

export function Footer() {
  const { t } = useTranslation();

  return (
    <section className="py-4 bg-white">
      <Container>
        <Row>

          <Col md={6} className="text-secondary">
            &copy; Taldyqorgan.com 2023
          </Col>

          <Col md={6} className="d-md-flex">
            <div className="ms-md-auto">
              <Link to="/" className="text-secondary">{t('Navigation.AboutUs')}</Link>
              &nbsp; &#183; &nbsp;
              <Link to="/" className="text-secondary">{t('Navigation.FAQ')}</Link>
              &nbsp; &#183; &nbsp;
              <Link to="/" className="text-secondary">{t('Navigation.TermsAndConditions')}</Link>
              &nbsp; &#183; &nbsp;
              <Link to="/" className="text-secondary">{t('Navigation.Contact')}</Link>
            </div>
          </Col>

        </Row>
      </Container>
    </section>
  );
}

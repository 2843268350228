import { Account } from 'types/Account';

export interface AuthState {
  account: Account|null,
  expiryDate: string|null,
  token: string|null,
  error?: string|null,
}

export const initialState: AuthState = {
  account: null,
  expiryDate: null,
  token: null,
  error: null,
};

export const typeGuard = (object: any): boolean => {
  if (typeof object !== 'object') {
    return false;
  }

  const requiredProperties = ['account', 'expiryDate', 'token'];

  return Object.keys(object).filter(
    (key: string) => requiredProperties.includes(key)
  ).length === requiredProperties.length;
};

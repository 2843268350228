import { Offer as Parent } from 'types/Offer';

export interface Offer extends Parent {
}

export const emptyOffer: Offer = {
  id: 0,
  title: "",
  vendorName: "",
  imageHd: "",
  imagePreview: "",
  discount: 0,
  description: "",
};

import React, { useEffect } from 'react';
import { toast } from 'react-toastify';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';

import * as BootstrapIcons from 'react-bootstrap-icons';

import * as SolidIcons from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Offer } from 'features/public/offers/Offer';

import { Link } from 'components/helpers/RouterWrapper';

import { useAppSelector, useAppDispatch } from 'app/hooks';
import { deleteOneOffer, fetchAllOffers, selectAll } from 'features/private/offers/offersSlice';

export function OfferManagePage() {

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchAllOffers());
  }, [dispatch]);

  // TODO: fetch loading status!

  const offers: Offer[] = useAppSelector(selectAll);

  const deleteHandler = (offerId: number) => {
    dispatch(deleteOneOffer(offerId))
      .then(() => toast.success("Deleted successfully"));
  };

  return (
    <section className="section-padding">
      <Container>

        <Row>
          <Col>
            <h2>My Offers</h2>
          </Col>
        </Row>

        <Row>
          <Col>
            <Link to={`/account/offer/add`} className="btn btn-primary">
              <FontAwesomeIcon icon={SolidIcons.faPlus} /> Add New Offer
            </Link>
          </Col>
        </Row>

        <Row>
          <Col>
            <Table className="mt-4" bordered={false} responsive={true} striped={true} variant="light">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Title</th>
                  <th>Store</th>
                  <th>Discount</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
              {offers.map((offer: Offer, idx) => (
                <tr key={idx}>
                  <td className="align-middle">{offer.id}</td>
                  <td className="align-middle">{offer.title}</td>
                  <td className="align-middle">{offer.vendorName}</td>
                  <td className="align-middle">{offer.discount} %</td>
                  <td>
                    <Link to={`/account/offer/${offer.id}`} className="btn btn-sm btn-secondary me-2">
                      <BootstrapIcons.Pencil size={15} className="mb-1" /> Edit
                    </Link>
                    <Button className="me-2" size="sm" variant="secondary"
                            onClick={() => deleteHandler(offer.id)}
                    >
                      <BootstrapIcons.XSquare size={15} className="mb-1" /> Delete
                    </Button>
                  </td>
                </tr>
              ))}
              </tbody>
            </Table>
          </Col>
        </Row>

      </Container>
    </section>
  );
}

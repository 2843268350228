import { apiUrl } from 'app/conf';
import { ILoginFormValues } from 'components/forms/LoginForm/types';

export function signIn(credentials: ILoginFormValues) {

  if (process.env.NODE_ENV === 'development') {
    console.log("[API] Login request");
  }

  return fetch(apiUrl + '/api/auth/sign-in/', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(credentials),
  })
    .then(response => response.json());
}

import { apiUrl } from 'app/conf';

export function fetchAll(token: string) {

  if (process.env.NODE_ENV === 'development') {
    console.log("[API] All private/offers request");
  }

  return fetch(apiUrl + '/api/private/offers/', {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  })
    .then(response => {
      if (response.status !== 200) {
        // TODO: rework error handling because response body may contain error message
        return Promise.reject('HTTP status is ' + response.status + ', but 200 is expected.');
      }
      return response.json();
    });
}

export function fetchOne(token: string, offerId: number) {

  if (process.env.NODE_ENV === 'development') {
    console.log("[API] One private/offer request; id: ", offerId);
  }

  return fetch(apiUrl + '/api/private/offers/' + offerId, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  })
    .then(response => {
      if (response.status !== 200) {
        // TODO: rework error handling because response body may contain error message
        return Promise.reject('HTTP status is ' + response.status + ', but 200 is expected.');
      }
      return response.json();
    });
}

import React from 'react';
import { NavLink as RouterNavLink, NavLinkProps, Link as RouterLink, LinkProps, To } from 'react-router-dom';
import { i18n } from 'i18next';
import { useTranslation } from 'react-i18next';

import { languages } from 'app/conf';

export function getLocationWithLang(to: To, i18n: i18n): To {
  const location = typeof to === 'string' ? to : (to.pathname || '/');

  if (Array.from(location)[0] !== '/') {
    throw new Error("The language prefix can only be added to an absolute path");
  }

  return languages.length > 1 ? '/' + i18n.language + location : location;
}

export function NavLink({children, to, ...props}: NavLinkProps & React.RefAttributes<HTMLAnchorElement>) {
  const { i18n } = useTranslation();

  return (
    <RouterNavLink to={getLocationWithLang(to, i18n)} {...props}>
      {children}
    </RouterNavLink>
  );
}

export function Link({children, to, ...props}: LinkProps & React.RefAttributes<HTMLAnchorElement>) {
  const { i18n } = useTranslation();

  return (
    <RouterLink to={getLocationWithLang(to, i18n)} {...props}>
      {children}
    </RouterLink>
  );
}

import React, { useContext } from 'react';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import { Link } from 'components/helpers/RouterWrapper';

import { Account } from 'types/Account';

import { useAppDispatch } from 'app/hooks';
import { logout } from 'features/account/accountSlice';
import { AuthContext } from 'features/account/AuthContext';

export function AccountPage() {
  const dispatch = useAppDispatch();

  const authState = useContext(AuthContext);
  const account: Account|null = authState.account;

  if (!account) {
    return (
      <section className="section-padding">
        <Container>
          <Row>
            <Col sm={10} lg={8} xxl={5} className="mx-auto p-lg-5">
              <h3 className="text-danger">Unauthorized access</h3>
              <p>Please <Link to="/login/">sign in</Link>.</p>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }

  return (
    <section className="section-padding">

      <Container>

        <h2>Hello, <u>{account.username}</u>!</h2>
        <Button onClick={() => dispatch(logout())}>Logout</Button>

      </Container>

    </section>
  );
}
